import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import GetStartedEmailForm from './Forms/GetStartedEmailForm';

import footerCTA from '~/data/contentful/footerCTA';

function CTA() {
  const cta = footerCTA?.data?.footerCta;

  return (
    <section className="relative bg-black py-20 lg:py-24">
      <div className="container-padding">
        <div className="grid md:grid-cols-2 gap-12 lg:gap-20 items-center">
          <div className="text-center md:max-w-xl md:text-left">
            <h2 className="mb-8 text-white md:max-w-sm u-h2">{cta?.heading}</h2>

            <GetStartedEmailForm />

            <div className="flex flex-wrap justify-center mb-8 space-x-8 md:space-x-0 xl:space-x-8 md:justify-around xl:justify-start">
              {cta?.footerFeatures.map((feature, i, arr) => (
                <li
                  key={`footerFeatures${i}`}
                  className={`flex items-center text-sm font-medium text-white list-none ${i === arr.length - 1 ? ' mt-3  md:mt-0' : ' mb-0 md:mb-2 lg:mb-0'}`}
                >
                  <div className="mr-3">
                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.51032 6.57833L5.50553 9.91675C5.50553 9.91675 6.94203 4.89378 13.8291 1.24353"
                        stroke="#F3B545"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  {feature}
                </li>
              ))}
            </div>
            <Link href={cta?.linkUrl || '#'}>
              <a className="text-white text-link text-link--white">{cta?.linkLabel}</a>
            </Link>
          </div>

          <div className="hidden md:block">
            <Image draggable="false" src={'/images/footer-cta.png'} width={635} height={279} alt={cta?.image.title || ""} className="w-full max-w-2xl" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default CTA;
