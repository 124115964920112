export default {
  data: {
    footerCta: {
      __typename: 'FooterCta',
      sys: {
        __typename: 'Sys',
        id: '8F6yXk3NKjasLY5lL2tHY'
      },
      heading: 'Serve smarter with Proof',
      image: {
        __typename: 'Asset',
        url: '',
        title: 'cta',
        width: 635,
        height: 279
      },
      footerFeatures: ['Vetted Servers', 'Nationwide Delivery', 'Verified Live Updates'],
      linkLabel: 'See pricing for your state',
      linkUrl: '/pricing'
    }
  },
  loading: false,
  networkStatus: 7
};
