import Link from 'next/link';
import React, { useRef } from 'react';
import dynamic from 'next/dynamic';
const Player = dynamic(()=>import('@lottiefiles/react-lottie-player').then((mod) => mod.Player));

function LandingFooter({ navData, customHeaderButton = [] }) {
  const lottieRef = useRef();
  return (
    <footer>
      <div className="py-6 px-8 bg-dark-blue flex text-white items-center justify-between">
        <a className="block w-24 transition-opacity duration-200 ease-in-out contain hover:opacity-75">
          <Player
            src={navData?.data?.navigation?.logoWhiteLottieFile?.url}
            ref={lottieRef}
            keepLastFrame={true}
            loop={false}
            rendererSettings={{
              hideOnTransparent: true,
              progressiveLoad: true
            }}
          />
        </a>
        <span>© {new Date().getFullYear()} Proof Technology, Inc.</span>
        <Link href={`${customHeaderButton && customHeaderButton.length > 0 && customHeaderButton[0].link.url.length > 0 ? customHeaderButton[0].link.url : 'https://app.proofserve.com/signup'}`}>
          <a
            data-id="landing-footer-primary-cta"
            className="w-auto px-6 py-3 btn hover:btn-white"
            onClick={() => {
              if (window.analytics) {
                analytics.track('Get Started', {
                  type: 'header'
                });
              }
            }}
          >
            {customHeaderButton && customHeaderButton.length > 0 && customHeaderButton[0].title.length > 0 ? customHeaderButton[0].title : 'Get started'}
          </a>
        </Link>
      </div>
    </footer>
  );
}

export default LandingFooter;
