import CTA from './CTA';
import Footer from './Footer';
import Header from './Header';

export default function Layout({ children, headerDark, hideCTA = false, navType='default', footerType='default', isLandingPage = false, customHeaderButton = null, className = '' }) {
  return (
    <div className={`${className || ''} main-container`}>
      {navType !== 'hidden' && <Header headerDark={headerDark} isLandingPage={isLandingPage} customHeaderButton={customHeaderButton} />}
      {children}
      {!hideCTA && !isLandingPage && <CTA />}
      {navType !== 'hidden' && <Footer footerType={footerType} isLandingPage={isLandingPage} customHeaderButton={customHeaderButton} />}
    </div>
  );
}
