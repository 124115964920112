import Link from 'next/link';
import React from 'react';
import LandingFooter from './Landing/LandingFooter';
import navData from '~/data/contentful/navData';

function Footer({ isLandingPage = false, footerType = 'default', customHeaderButton = [] }) {
  const footerNav = navData.data.navigation.footerNavigation.Footer;

  if (footerType === 'hidden') return null;

  if (isLandingPage || footerType === 'landing') {
    return <LandingFooter navData={navData} customHeaderButton={customHeaderButton} />;
  }

  return (
    <footer className="pt-20 pb-24 md:pt-24 md:pb-20">
      <div className="container">
        <div className="justify-between md:flex">
          <div className="text-center lg:w-4/12 md:text-left">
            <div className="inline-block w-8 mb-4">
              <div className="-ml-4">
                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1_46)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M25.747 11.377L25.0687 13.179C23.3361 17.782 23.1214 22.2899 24.2557 25.5848C26.371 23.8004 29.0493 22.7788 32.2962 22.7788C36.622 22.7788 39.4853 25.4982 39.4927 28.8832C39.5001 32.2775 36.6371 34.9962 32.2962 34.9962C29.3118 34.9962 26.309 34.1095 24.0418 32.084C21.9192 36.4292 21.8155 41.3899 21.8155 46.1419H27.5243V39.3385H32.2962C36.4585 39.3385 39.4072 37.9509 41.3093 36.0329C43.2285 34.0978 44.199 31.5079 44.199 28.8963C44.199 26.2846 43.2285 23.6947 41.3093 21.7596C39.4072 19.8417 36.4585 18.454 32.2962 18.454L30.036 18.455V14.5969L32.2962 14.5959C37.3361 14.5959 41.2891 16.3011 43.988 19.0224C46.6699 21.7265 48 25.3222 48 28.8963C48 32.4703 46.6699 36.066 43.988 38.7701C41.2891 41.4914 37.3256 43.1966 32.2857 43.1966L31.3253 43.1966V50H18.0145C18.0145 42.8752 17.577 35.0779 21.5044 28.8015C18.7748 23.6202 19.5246 17.098 21.518 11.802L22.1963 10L25.747 11.377ZM26.2719 28.9223C27.8679 30.5117 30.1017 31.1381 32.2962 31.1381C35.035 31.1381 35.6935 29.6771 35.6917 28.8918C35.69 28.0972 35.0135 26.6369 32.2962 26.6369C29.7842 26.6369 27.8114 27.4432 26.2719 28.9223Z"
                      fill="#1C1F26"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_46">
                      <rect width="64" height="64" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <h3 className="mb-8 text-3xl md:max-w-xs u-h3">The smarter way to serve legal documents</h3>
            <Link href="/how-it-works">
              <a className="btn">How it works</a>
            </Link>
          </div>
          <div className="pt-12 mt-12 border-t border-dashed md:w-7/12 md:pl-12 lg:pl-24 md:border-l md:mt-0 md:pt-0 md:border-t-0" style={{ borderColor: 'rgba(0, 0, 0, 0.1)' }}>
            <div className="grid grid-cols-2 gap-y-8 md:gap-y-16 md:grid-cols-3">
              {footerNav.map(({ name, items }, i) => (
                <div key={`col${name + i}`}>
                  <h4 className="text-sm u-h3">{name}</h4>
                  <div className="mt-5 space-y-2">
                    {items.map(item => (
                      <ul key={name + item.url} className="list-none">
                        <li>
                          <Link href={item.url}>
                            <a target={item.url[0] !== '/' ? '_blank' : ''} className="text-sm transition-opacity duration-200 ease-in-out opacity-50 hover:opacity-100">
                              {item.name}
                            </a>
                          </Link>
                        </li>
                      </ul>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-16 text-center md:mt-20 md:text-left">
          <div className="lg:w-7/12 md:pl-24 md:ml-auto md:flex">
            <p className="text-xs opacity-50">© {new Date().getFullYear()} Proof Technology, Inc.</p>
            <div className="flex justify-center mt-4 space-x-4 md:justify-start md:ml-24 md:mt-0">
              <Link href="/legal/privacy-policy">
                <a className="text-xs underline transition-opacity duration-200 ease-in-out opacity-50 hover:opacity-100">Terms & Conditions</a>
              </Link>
              <Link href="/legal/privacy-policy">
                <a className="text-xs underline transition-opacity duration-200 ease-in-out opacity-50 hover:opacity-100">Privacy Policy</a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
