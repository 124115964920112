import React, { useState } from 'react';
import { useRouter } from 'next/router';

export default function GetStartedEmailForm({ homepage, area }) {
  const router = useRouter();
  const [emailValue, setEmailValue] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    window.location = `https://app.proofserve.com/signup?email=${emailValue}`;
    if (window.analytics) {
      analytics.track('Get Started', {
        type: homepage ? 'homepage' : 'cta',
        emailProvided: !!emailValue
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="flex flex-wrap mb-5 space-y-2 md:flex-nowrap md:space-x-4 md:space-y-0">
        <input type="email" value={emailValue} onChange={e => setEmailValue(e.target.value)} placeholder="Enter your email address" className="w-full flex-0" />
        <input type="submit" value="Get started" data-id={`${area || 'footer-cta'}-primary-cta`} className="flex-1 w-full text-black md:w-auto" />
      </form>
    </div>
  );
}
